export function percentChanged(
  currentNumber: number,
  previousNumber: number | null,
): number | null {
  if (!previousNumber) {
    return null;
  }
  return Math.round(((currentNumber - previousNumber) / previousNumber) * 100);
}

interface Props {
  percent?: number | null;
  since?: string;
  value?: number | string;
}

export function PercentChangeMessage({
  percent,
  since = "last month",
  value,
}: Props) {
  let deltaMsg = "";
  if (percent === null || isNaN(percent) || !isFinite(percent)) {
    deltaMsg = "";
  } else if (value && percent > 0) {
    deltaMsg = `${value} ${since} (⇧ +${percent}%)`;
  } else if (value && percent < 0) {
    deltaMsg = `${value} ${since} (⇩ ${percent}%)`;
  } else if (percent === 0) {
    deltaMsg = `No change since ${since}`;
  } else if (percent > 0) {
    deltaMsg = `⇧ +${percent}% since ${since}`;
  } else if (percent < 0) {
    deltaMsg = `⇩ ${percent}% since ${since}`;
  }
  return <span>{deltaMsg}</span>;
}
